import { useRef } from 'react';

import { useIntersection } from 'hooks/useIntersection';

type Props = {
  children: () => JSX.Element;
  fallback: () => JSX.Element;
};

export const DynamicImport = ({ children, fallback: Fallback }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  if (intersected === false) {
    return (
      <div ref={ref}>
        <Fallback />
      </div>
    );
  }

  return children();
};
