/* eslint react-hooks/exhaustive-deps: "warn" */

import { useContext, useMemo } from 'react';

import { useRouter } from 'libs/router/useRouter';
import { getAbsoluteRoute } from 'libs/router/helpers';

import { ContentContext } from 'context/ContentContext';

import { SocialMedia } from 'ui/atoms/SocialMedia/SocialMedia';
import { Icon } from 'ui/atoms/Icon/Icon';

import { LocationBlock } from 'features/customers/organisms/LocationBlock/LocationBlock';
import { FormHubspot } from 'features/customers/organisms/FormHubspot/FormHubspot';

const formIds = {
  es: 'dd4f9597-767f-43a7-80fb-718b51bbad73',
  it: '3ac656ba-bb2d-491e-82cd-5160bf2422e8',
  fr: 'f7d1a513-9d24-4dc9-9b00-22051668e10f',
  default: '77a47228-7dfe-46bd-bc16-f545358d7ba6',
} as const;

const ContactUs = () => {
  const { query } = useRouter();
  const { secondaryNavigation, data: content, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const contactPageVars = useMemo(() => pageVars.contacts || {}, [pageVars.contacts]);

  const redirectUrl = useMemo(
    () => getAbsoluteRoute(secondaryNavigation.thank_you?.slug, { lang: `${query.lang}` }),
    [query.lang, secondaryNavigation.thank_you?.slug],
  );

  return (
    <div className="contact-us">
      <div className="contact-us__wrapper">
        <h1>{content.title}</h1>

        <div className="plan-collapse">
          <div className="plan-collapse__left contact-us__left">
            <h2>{contactPageVars.left_block_title}</h2>

            <h5>{content.title}</h5>

            <div className="contact-us__info">
              <div className="contact-us__contact">
                <Icon type="phone" className="icon-phone" />
                <span>{commonPageVars.phone}</span>
              </div>

              <div className="contact-us__contact">
                <Icon type="email" className="icon-email" />
                <span>
                  <a href={`mailto:${commonPageVars.email}`}>{commonPageVars.email}</a>
                </span>
              </div>
            </div>

            <div className="contact-us__social-media">
              <h5>{commonPageVars.social_media_title}</h5>

              <SocialMedia
                twitter={commonPageVars.twitter}
                facebook={commonPageVars.facebook}
                linkedin={commonPageVars.linkedin}
              />
            </div>

            <div className="plan-collapse-left__bg">
              <Icon type="contact-us-decoration" />
            </div>
          </div>

          <div className="plan-collapse__right">
            <FormHubspot data={formIds} redirectUrl={redirectUrl} />
          </div>
        </div>

        <div className="plan-line" />

        <div className="plan-location-wrapper">
          <LocationBlock
            className="headquarter"
            title={contactPageVars.headquarter_title_1}
            description={contactPageVars.headquarter_desc_1}
            type="headquarter-building"
          />

          <LocationBlock
            className="operational-office"
            title={contactPageVars.headquarter_title_2}
            description={contactPageVars.headquarter_desc_2}
            type="operational-office-building"
          />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ContactUs;
