/* eslint react-hooks/exhaustive-deps: "warn" */

import { useMemo } from 'react';

import { useScrollToTop } from 'hooks/useScrollToTop';

import { Breadcrumbs } from 'ui/atoms/Breadcrumbs/Breadcrumbs';
import { Loader } from 'ui/atoms/Loader/Loader';

import { AgentsProvider, useAgentsContext } from 'features/agents/helpers/AgentsContext/AgentsContext';
import { MAIN_TEMPLATE } from 'features/agents/helpers/utils';

import styles from './AgentsPage.module.scss';

import { templates } from '../../templates';

const baseClass = 'agents-page';

const AgentsPage = () => {
  const { type, loading, hasGuardError } = useAgentsContext();

  useScrollToTop({ dep: type });

  const breadcrumbs = useMemo(() => {
    return [
      { name: 'Agents', slug: 'ai-agents', key: 1, query: { type: MAIN_TEMPLATE } },
      {
        name:
          type.charAt(0).toUpperCase() +
          type
            .slice(1)
            .split(/(?=[A-Z])/)
            .join(' '),
        isLast: true,
        key: 2,
      },
    ];
  }, [type]);

  const template = useMemo(() => {
    if (hasGuardError) {
      return templates['book-demo']();
    }

    return templates?.[type]?.() || null;
  }, [type, hasGuardError]);

  return (
    <div className={styles[`${baseClass}-wrapper`]}>
      <div className={styles[`${baseClass}-container`]}>
        {type !== MAIN_TEMPLATE && !hasGuardError && <Breadcrumbs data={breadcrumbs} />}

        <Loader loading={loading} height={400}>
          {template}
        </Loader>
      </div>
    </div>
  );
};

const withAgentsProvider = (Component: typeof AgentsPage) => () => {
  return (
    <AgentsProvider>
      <Component />
    </AgentsProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default withAgentsProvider(AgentsPage);
