/* eslint react-hooks/exhaustive-deps: "warn" */

import cn from 'classnames';

import styles from './IncludedBlockLoader.module.scss';

const baseClass = 'included-block-loader';

export const IncludedBlockLoader = ({ className = '' }) => {
  return (
    <div className={cn(styles[baseClass], className)}>
      <div />
    </div>
  );
};
