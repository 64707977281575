export const FormHubspotStringCss = `
  #hubspotForm {
    background: #fff;

    form.hs-form {
      display: grid;
      grid-row-gap: 14px;
  
      @media (max-width: 480px) {
        grid-row-gap: 0;
      }
  
      input,
      textarea,
      select.hs-input {
        height: 60px;
        border: 1px solid #d8e5f7;
        font-size: 18px;
        color: #425289;
  
        &:focus,
        &:focus-visible,
        &:active {
          border-color: #077be7;
          outline: none;
        }
      }
  
      label {
        display: flex;
        justify-content: flex-start;
        color: #3e4f82;
        margin-bottom: 4px;
        font-size: 14px;
        align-items: center;
      }
      
      .hs-form-field {
        @media (max-width: 480px) {
          margin-top: 20px;
        }
      }
  
      textarea,
      select.hs-input {
        font-size: 14px;
      }
  
      .hs-error-msg {
        color: #f72942;
        margin-top: 4px;
        font-size: 14px;
      }
  
      .legal-consent-container {
        color: #425289;
        text-align: left;
  
        a {
          color: #425289;
          text-decoration: underline;
        }

        p {
          margin-top: -14px;

          @media (max-width: 480px) {
            margin-top: 0;
          }
        }
      }
  
      .actions {
        display: flex;
  
        input {
          width: 100%;
          background: #0347a6;
          color: #fff;
          cursor: pointer;
  
          &:hover {
            background: #055fc6;
            transition: .2s ease;
          }
        }
      }
    }
    
    .hs-form-radio {
      label {
        margin: 0 !important;
        cursor: pointer;
      
        input {
          margin: 0 12px 0 0;
          height: 40px;
        }

        input[type="radio"] {
          height: 25px;
        }
      }
    }
  }
`;
