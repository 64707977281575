/* eslint react-hooks/exhaustive-deps: "warn" */

import { createContext, useMemo, useCallback, useContext, useEffect, useState } from 'react';

import type { CountryParams, SearchDataParams } from 'libs/http/api/dashboard/dashboard.types';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { getCookie, removeCookie } from 'libs/node';
import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

import { useRequest } from 'hooks/useRequest';

import type { AgentsContextProps, AgentsProviderProps, TemplatesType, AgentViewType } from './AgentsContext.types';
import { agentTypeKeyMap, isFreeType } from '../utils';

export const MAIN_TEMPLATE = 'agents';

const verifySSOTokenService = async () => {
  const ssoToken = getCookie('sso-token');

  if (ssoToken === null) {
    return Promise.reject(false);
  }

  try {
    await dashboard.auth.details({ headers: { Authorization: `Token ${ssoToken}` } });

    return Promise.resolve(true);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    return Promise.reject(false);
  }
};

const getDashboardHeaders = () => ({
  Authorization: `Token ${getCookie('sso-token')}`,
  'Current-Application': 'ai_agent',
});

const getInitialAgentSearchValues = () => ({ company: '', country: '' });

const initialSelectData = { data: {} };

const AgentsContext = createContext<AgentsContextProps | undefined>(undefined);
export const AgentsProvider = ({ children }: AgentsProviderProps) => {
  const { push, query } = useRouter();
  const { additionals, secondaryNavigation } = useContext(ContentContext);

  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState<boolean | undefined>(undefined);

  const [agentViewType, setAgentViewType] = useState<AgentViewType>();
  const [agentSearchValues, setAgentSearchValues] = useState({ company: '', country: '' });

  const { request: requestCountries, loading: loadingCountries, data: dataCountries } = useRequest<CountryParams[]>();

  const {
    request: requestSearch,
    loading: loadingSearch,
    data: dataSearch,
    errors: errorsSearch,
  } = useRequest<SearchDataParams[]>();

  const {
    request: requestSelect,
    loading: loadingSelect,
    data: dataSelect,
    errors: errorsSelect,
  } = useRequest<Record<string, string>>(initialSelectData);

  const type = useMemo(() => (query.type ? `${query.type}` : 'agents') as TemplatesType, [query.type]);
  const setType = useCallback(
    (newType?: string, newAgent?: string) => {
      const hasAuthParams = newType === 'confirm-password';

      push(
        secondaryNavigation.ai_agents?.slug,
        { lang: `${query.lang}` },
        {
          ...(hasAuthParams && {
            ...(query.uid && { uid: `${query.uid}` }),
            ...(query.token && { token: `${query.token}` }),
          }),

          ...(newType && { type: newType }),

          ...(query.agent && { agent: `${query.agent}` }),
          ...(newAgent && { agent: newAgent }),
        },
        { shallow: true },
      );
    },
    [push, query.agent, query.lang, query.token, query.uid, secondaryNavigation.ai_agents?.slug],
  );

  useEffect(() => {
    const event = [
      'popstate',
      () => {
        if (!secondaryNavigation.ai_agents?.slug) {
          return;
        }

        const searchParams = new URLSearchParams(window.location.search);

        const newQuery = {} as Record<string, string>;
        searchParams.forEach((value, key) => {
          newQuery[key] = value;
        });

        push(
          secondaryNavigation.ai_agents.slug,
          { lang: `${newQuery.lang}` },
          {
            ...(newQuery.uid && { uid: newQuery.uid }),
            ...(newQuery.token && { token: newQuery.token }),
            ...(newQuery.type && { type: newQuery.type }),
            ...(newQuery.agent && { agent: newQuery.agent }),
          },
        );
      },
    ] as const;

    window.addEventListener(...event);

    return () => {
      window.removeEventListener(...event);
    };
  }, [push, secondaryNavigation.ai_agents?.slug]);

  const agentData = useMemo(
    () => additionals.agents?.data.find((item) => item.slug === query.agent),
    [additionals.agents?.data, query.agent],
  );

  const hasGuardError = useMemo(
    () => Boolean(errorsSearch.error_guard || errorsSelect.error_guard),
    [errorsSearch.error_guard, errorsSelect.error_guard],
  );

  const verifySSOToken = useCallback(
    async (newAgent?: string) => {
      try {
        const result = await verifySSOTokenService();

        return result;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        removeCookie('sso-token');

        if (query.type === 'agent') {
          setType('register', newAgent);
        }

        return Promise.reject(false);
      }
    },
    [query.type, setType],
  );

  const onClearAgentSearchValues = useCallback(() => {
    setAgentViewType(undefined);
    setAgentSearchValues(getInitialAgentSearchValues());
  }, []);

  const onChangeAgentSearchValues = useCallback(
    (key: string, value: string) => setAgentSearchValues((prevState) => ({ ...prevState, [key]: value })),
    [],
  );

  useEffect(() => {
    const ssoToken = getCookie('sso-token');
    setAuthenticated(Boolean(ssoToken));
  }, []);

  useEffect(() => {
    if (!query.agent || !query.uid || !query.token) {
      return;
    }

    (async () => {
      setLoading(true);

      try {
        await verifySSOTokenService();

        setAuthenticated(true);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        if (query.type !== 'confirm-password') {
          setType('confirm-password');
        }

        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [query.agent, query.token, query.type, query.uid, setType]);

  useEffect(() => {
    if (query.agent) {
      onClearAgentSearchValues();
    }
  }, [onClearAgentSearchValues, query.agent]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        await verifySSOToken();
      } finally {
        setLoading(false);
      }
    })();
  }, [verifySSOToken]);

  useEffect(() => {
    if (type !== 'agent' || !agentData) {
      return;
    }

    requestCountries(
      dashboard.agents.countries({
        type: agentTypeKeyMap[agentData.name],
        lang: `${query.lang}`,
        headers: getDashboardHeaders(),
      }),
    );
  }, [agentData, query.lang, requestCountries, type]);

  const signup = useCallback(() => {
    const firstFreeAgent = additionals.agents?.data?.reduce<string | undefined>((acc, curr) => {
      if (!acc && isFreeType(curr.type)) {
        acc = curr.slug;
      }

      return acc;
    }, undefined);

    if (query.type !== 'register') {
      setType('register', firstFreeAgent);
    }
  }, [additionals.agents?.data, query.type, setType]);

  const updateType = useCallback(
    async (newType: TemplatesType, newAgent?: string) => {
      if (['login', 'register'].includes(newType)) {
        if (query.type !== newType) {
          setType(newType, newAgent);
        }

        return;
      }

      if (newType === 'agent') {
        window.scrollTo({ top: 0 });

        try {
          await verifySSOToken(newAgent);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);

          setType('register');
        }

        if (query.type !== newType || query.agent !== newAgent) {
          setType(newType, newAgent);
        }

        return;
      }

      if (query.type !== newType) {
        setType(newType, newAgent);
      }
    },
    [query.agent, query.type, setType, verifySSOToken],
  );

  const select = useCallback(
    ({ id }: { id: string }) => {
      setAgentViewType('select');

      if (!additionals.agents?.data.length) {
        return;
      }

      if (!agentData) {
        return;
      }

      requestSelect(
        dashboard.agents.company({
          id,
          type: agentTypeKeyMap[agentData.name],
          headers: getDashboardHeaders(),
        }),
      );
    },
    [additionals.agents?.data.length, agentData, requestSelect],
  );

  const search = useCallback(() => {
    if (agentSearchValues.company.trim().length === 0) {
      return;
    }

    setAgentViewType('search');

    if (!agentData) {
      return;
    }

    requestSearch(
      dashboard.agents.search({
        title: agentSearchValues.company,
        location_countries: [agentSearchValues.country],
        type: agentTypeKeyMap[agentData.name],
        headers: getDashboardHeaders(),
      }),
    );
  }, [agentData, agentSearchValues.company, agentSearchValues.country, requestSearch]);

  return (
    <AgentsContext.Provider
      value={{
        data: additionals.agents?.data || [],
        type,
        authenticated,
        setAuthenticated,
        updateType,
        agent: query.agent ? `${query.agent}` : undefined,
        agentData,
        loading,
        signup,

        hasGuardError,

        dataCountries,
        loadingCountries,

        agentViewType,

        agentSearchValues,
        onClearAgentSearchValues,
        onChangeAgentSearchValues,

        select,
        dataSelect,
        loadingSelect,
        errorsSelect,

        search,
        dataSearch,
        loadingSearch,
        errorsSearch,
      }}
    >
      {children}
    </AgentsContext.Provider>
  );
};

export const useAgentsContext = (): AgentsContextProps => {
  const context = useContext(AgentsContext);

  if (!context) {
    throw new Error('useAgentsContext must be used within an AgentsProvider');
  }

  return context;
};
