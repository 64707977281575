import { useEffect } from 'react';

type Props = {
  dep?: any;
  behavior?: 'auto' | 'smooth' | 'instant';
};

export const useScrollToTop = ({ dep, behavior = 'auto' }: Props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior,
    });
  }, [dep, behavior]);
};
