import { type ReactNode, FormEvent, useMemo, useState, useContext, useCallback } from 'react';

import { useRouter } from 'libs/router/useRouter';
import { isNotBusinessEmail } from 'libs/string';

import { LeadContext } from 'context/LeadContext';
import { ContentContext } from 'context/ContentContext';

import { Input } from 'ui/atoms/Input/Input';
import { Button } from 'ui/atoms/Button/Button';

interface InfoBlockProps {
  title?: string;
  description?: ReactNode;

  type?: string;
  className?: string;
  disabled?: boolean;
  transparent?: boolean;

  persistButton?: boolean;
  buttonText?: string;
  buttonHref?: string;
  buttonOnClick?: () => void;
}

export const InfoBlock = ({
  className,
  title,
  description,
  disabled,
  transparent,
  type,

  persistButton = false,
  buttonText,
  buttonHref,
  buttonOnClick,
}: InfoBlockProps) => {
  const { query, push } = useRouter();
  const { secondaryNavigation, data: content, pageVars } = useContext(ContentContext);
  const { leadData, setLeadData } = useContext(LeadContext);

  const [error, setError] = useState(false);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const notBusinessEmail = useMemo(() => isNotBusinessEmail(leadData.email), [leadData.email]);

  const onChange = useCallback(
    (target: string) => (value: string) => setLeadData({ [target]: value }),
    [leadData, setLeadData],
  );

  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (secondaryNavigation.book_demo?.slug && leadData.email && !notBusinessEmail) {
      return push(
        secondaryNavigation.book_demo.slug,
        { lang: `${query.lang}` },
        leadData.email ? { email: leadData.email } : {},
      );
    }

    setError(true);
  };

  if (!title && !description) {
    return null;
  }

  return (
    <div className={`info-block ${className ? className : ''}`}>
      <div
        className={`info-block__wrapper ${transparent ? 'transparent' : ''} ${
          type === 'customers-page'
            ? '__customers'
            : type === 'lead-generation'
            ? '__lead-generation'
            : type === 'careers'
            ? '__careers'
            : ''
        }`}
      >
        {title && <h1>{title}</h1>}

        {description && <h2 dangerouslySetInnerHTML={{ __html: description }} />}

        {!disabled && (persistButton || content.type_of_book_a_demo) ? (
          <>
            {buttonText ? (
              <>
                {content.type_of_book_a_demo === 'download' && (buttonHref || buttonOnClick) && (
                  <Button size={64} href={buttonHref} onClick={buttonOnClick} target="_blank">
                    {buttonText}
                  </Button>
                )}

                {!content.type_of_book_a_demo && (buttonHref || buttonOnClick) && (
                  <Button size={64} href={buttonHref} onClick={buttonOnClick} target="_blank">
                    {buttonText}
                  </Button>
                )}

                {content.type_of_book_a_demo === 'free-trial' && secondaryNavigation.free_trial?.slug && (
                  <Button size={64} route={secondaryNavigation.free_trial.slug}>
                    {buttonText}
                  </Button>
                )}

                {content.type_of_book_a_demo === 'book-demo' && secondaryNavigation.book_demo?.slug && (
                  <Button size={64} route={secondaryNavigation.book_demo.slug}>
                    {buttonText}
                  </Button>
                )}
              </>
            ) : null}

            {content.type_of_book_a_demo === 'input-button' && (
              <>
                <form className="input-button" onSubmit={onSubmit}>
                  <Input
                    ariaLabel={commonPageVars.work_email}
                    text={commonPageVars.work_email}
                    dataTestId="form-test-email"
                    htmlType="email"
                    value={leadData.email}
                    onChange={onChange('email')}
                    hasError={error || notBusinessEmail}
                  />

                  {error || notBusinessEmail ? (
                    <span className="input-error-label">
                      {notBusinessEmail
                        ? commonPageVars.business_email_validation
                        : commonPageVars.book_demo_validation}
                    </span>
                  ) : null}

                  <Button size={64} htmlType="submit">
                    {buttonText}
                  </Button>
                </form>
              </>
            )}

            {content.type_of_book_a_demo === 'api-buttons' && (
              <div className="api-buttons">
                {commonPageVars.view_docs && (
                  <Button size={64} type="transparent" href="https://api.globaldatabase.com/docs/v2/">
                    {commonPageVars.view_docs}
                  </Button>
                )}

                {secondaryNavigation.free_trial?.slug && (
                  <Button size={64} route={secondaryNavigation.free_trial.slug}>
                    {commonPageVars.free_trial}
                  </Button>
                )}
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};
