import { MutableRefObject, useEffect, useState } from 'react';

interface Props {
  when?: boolean;
  deps?: any[];
  threshold?: number;
}

export const useIntersection = (
  ref: MutableRefObject<Element | null>,
  { when = true, deps = [], threshold = 0.01 }: Props = {},
) => {
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    if (!when || !ref?.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRect.height > 0 && entry.intersectionRatio >= threshold) {
            setHasIntersected(true);
          }
        });
      },
      { threshold },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, when, threshold, ...deps]);

  return hasIntersected;
};
