/* eslint react-hooks/exhaustive-deps: "warn" */

import { useContext } from 'react';

import { ContentContext } from 'context/ContentContext';

import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';

import { CardsAgents } from 'features/agents/organisms/CardsAgents/CardsAgents';
import { useAgentsContext } from 'features/agents/helpers/AgentsContext/AgentsContext';

import styles from './AgentsTemplate.module.scss';

const baseClass = 'agents';

export const AgentsTemplate = () => {
  const { data } = useContext(ContentContext);
  const { authenticated, signup } = useAgentsContext();

  return (
    <div className={styles[`${baseClass}-wrapper`]}>
      <InfoBlock
        title={data.title}
        description={data.description}
        {...(authenticated === false && {
          persistButton: true,
          buttonText: 'Sign Up For Free',
          buttonOnClick: signup,
        })}
      />

      <CardsAgents />
    </div>
  );
};
